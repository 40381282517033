import { waitAuth } from "~/utils/middleware";

import { PBEnums } from "~/types/pb/enums";

import { log } from "~/composables/log";

import Abonnements = PBEnums.Abonnements;

export default defineNuxtRouteMiddleware(async () => {
    await waitAuth(Abonnements.Drive);

    if (__subscription().hasDrive) {
        log("❌ Has Drive");
        return navigateTo("/");
    }

    log("✅ Has no Drive");
});
